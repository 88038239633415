<template lang="pug">
div
  base-snackbar(
    :errors.sync="orderPutErrors"
  )
  v-overlay(
    absolute
    :value="orderGetting || orderAttachInvoicePosting"
  )
    v-progress-circular(
      indeterminate
      :size="100"
    )
  v-toolbar(
    color="primary"
    dark
    dense
    elevation="0"
  )
    span Edit Order
    v-spacer
    v-btn.mr-2(
      v-if="!orderData.invoiceCount"
      color="purple darken-3"
      dark
      x-small
      @click="generateInvoice"
    )
      span Generate Invoice
    v-btn.mr-2(
      v-else
      color="error"
      dark
      x-small
      @click="$router.push({ name: 'orders.show', params: { orderId: $route.params.orderId } })"
    )
      span Cancel
    v-btn.mr-2(
      color="purple darken-3"
      dark
      x-small
      @click="$router.push({ name: 'orders.table' })"
    )
      span Orders View
    v-btn(
      dark
      text
      x-small
      :loading="orderPutting"
      @click="updateOrder"
    )
      v-icon mdi-content-save
  v-overlay(
    absolute
    v-model="orderPutting"
  )
    v-progress-circular(
      indeterminate
      :size="100"
    )
  edit-order(
    :field-attributes="orderFieldAttributes"
    :input-fields.sync="orderFields"
    :default-field-values="orderData"
    :error-messages="$objectChangeKeyCase(orderPutErrors)"
  )
  v-toolbar(
    color="primary"
    dense
    dark
    elevation="0"
  )
    span Details
    v-spacer
    v-btn(
      color="green"
      dark
      x-small
      dense
      @click="addDetailDialog = true"
    )
      span Add
  v-data-table(
    :items="orderDetails"
    dense
    :headers="headers"
    hide-default-footer
  )
    template(v-slot:body.append)
      tr
        td(colspan="6" align="right")
          strong Total
        td(align="right")
          span {{ (totalAmount || 0).phpFormat() }}
    template(v-slot:item="{ item }")
      tr
        td {{ item.stock.description }}
        td {{ (item.discount || 0).phpFormat() }}
        td {{ item.orderDetailStatusId }}
        td {{ item.dateAllocated.dateFormat() }}
        td(align="right") {{ item.quantity }}
        td(align="right") {{ (item.unitPrice || 0).phpFormat() }}
        td(align="right") {{ ((item.quantity * (item.unitPrice - item.discount)) || 0).phpFormat() }}
        td
          v-btn(
            text
            x-small
            color="yellow darken-3"
            :disabled="orderData.status_id === 'CP' && orderData.invoiceCount"
            @click="openEditDetailDialog(item)"
          )
            v-icon mdi-pencil
          v-btn(
            text
            x-small
            color="red"
            :disabled="orderData.status_id === 'CP' && orderData.invoiceCount"
            :loading="orderDetailDeleting"
            @click="deleteOrderDetail(item.id)"
          )
            v-icon mdi-delete
  edit-detail(
    ref="editDetail"
    v-model="editDetailDialog"
    :order-data="orderData"
    :excluded-stocks="stocks"
    :stocks="stockHasFeedDetailList"
    :statuses="orderDetailStatusIndexList"
  )
  create-detail(
    v-model="addDetailDialog"
    :order-data="orderData"
    :excluded-stocks="stocks"
    :stocks="stockHasFeedDetailList"
    :statuses="orderDetailStatusIndexList"
  )
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import orderRepository from '@/repositories/order.repository'
import orderDetailStatusRepository from '@/repositories/order-detail-status.repository'
import VuePostApi from '@/libs/classes/VuePostApi.class'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import orderDetailRepository from '@/repositories/order-detail.repository'
import VueGetApi from '@/libs/classes/VueGetApi.class'
import { getVars, putVars, postVars, requestVars } from '@/libs/api-helper.extra'

const { objectChangeKeyCase } = Window

const [orderDetailDeleteVars, orderDetailDeleteVarNames] = requestVars({ identifier: 'order-detail', request: 'delete', hasData: false })
const [orderDetailPostVars, orderDetailPostVarNames] = requestVars({ identifier: 'order-detail', request: 'post', hasData: false })
const [stockHasFeedDetailVars, stockHasFeedDetailVarNames] = requestVars({ identifier: 'stock-has-feed-detail' })
const [orderDetailStatusIndexVars, orderDetailStatusIndexVarNames] = requestVars({ identifier: 'order-detail-status-index' })
const [orderAttachInvoiceVars, orderAttachInvoiceVarNames] = requestVars({ identifier: 'order-attach-invoice', request: 'post', hasData: false })

const orderDetailPostHandler = new VueRequestHandler(null, orderDetailPostVarNames)
const orderDetailDeleteHandler = new VueRequestHandler(null, orderDetailDeleteVarNames)
const stockHasFeedDetailHandler = new VueRequestHandler(null, stockHasFeedDetailVarNames)
const orderDetailStatusIndexHandler = new VueRequestHandler(null, orderDetailStatusIndexVarNames)
const orderAttachInvoiceHandler = new VueRequestHandler(null, orderAttachInvoiceVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Item', value: 'stockId' },
    { text: 'Discount', value: 'discount' },
    { text: 'Order Detail Status', value: 'orderDetailStatusId' },
    { text: 'Date Allocated', value: 'dateAllocated' },
    { text: 'Quantity', value: 'quantity' },
    { text: 'Unit Price', value: 'unitPrice' },
    { text: 'Amount', sortable: false },
    { text: 'Config', sortable: false },
  ],
})

const orderDetailDialogVars = () => ({
  editDetailDialog: false,
  addDetailDialog: false,
})

export default {
  name: 'EditOrder',
  components: {
    editOrder: () => import('./../forms/OrderForm'),
    editDetail: () => import('./EditDetail'),
    createDetail: () => import('./CreateDetail'),
    invoiceCreate: () => import('./../forms/InvoiceForm'),
  },
  created() {
    this.getOrder()
    this.initWebsockets()
    this.getStockHasFeedDetail()
    this.getOrderDetailStatusIndex()
  },
  data: () => ({
    orderFields: {},
    orderData: {},
    orderDetails: [],
    ...stockHasFeedDetailVars,
    ...orderDetailStatusIndexVars,
    ...orderDetailPostVars,
    ...orderDetailDeleteVars,
    ...orderAttachInvoiceVars,
    ...getVars('order', {}, { list: false }),
    ...tableVars(),
    ...putVars('order-detail'),
    ...putVars('order'),
    ...orderDetailDialogVars(),
  }),
  computed: {
    totalAmount() {
      return this.orderDetails.reduce((a, b) => a + (b.quantity * (b.unitPrice - b.discount)), 0)
    },
    orderFieldAttributes() {
      const disabled = Boolean(this.orderData.invoiceCount)
      return {
        orderId: {
          disabled,
        },
        customer: {
          disabled,
        },
        orderDate: {
          disabled,
        },
        deliveryDate: {
          disabled,
        },
        shippingFee: {
          disabled,
        },
      }
    },
    stocks() {
      return this.orderDetails.map(item => item.stockId)
    },
  },
  watch: {
    totalAmount() {
      this.updateInvoiceAmountDue()
    },
    orderData() {
      this.setInvoiceDueDate()
    },
    'orderFields.paymentTerms': function () {
      this.setInvoiceDueDate()
    },
  },
  methods: {
    attachInvoice() {
      const handler = orderAttachInvoiceHandler
      const repository = orderRepository.attachInvoice
      handler.setVue(this)
      handler.execute(repository, [this.orderData.id], () => {
        this.$router.push(`/orders/${this.orderData.id}/show`)
      })
    },
    getStockHasFeedDetail() {
      const handler = stockHasFeedDetailHandler
      const repository = stockRepository.hasFeedDetail
      handler.setVue(this)
      handler.execute(repository)
    },
    getOrderDetailStatusIndex() {
      const handler = orderDetailStatusIndexHandler
      const repository = orderDetailStatusRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    deleteOrderDetail(id) {
      const conf = {
        title: 'Warning',
        icon: 'mdi-alert',
        color: 'error',
      }
      this.$confirm('Are you sure you want to remove this item?', conf)
        .then(this.orderDetailDeleteRequest(id))
    },
    orderDetailDeleteRequest(id) {
      return confirm => {
        if (!confirm) return
        const repository = orderDetailRepository.delete
        orderDetailDeleteHandler.setVue(this)
        orderDetailDeleteHandler.execute(repository, [id])
      }
    },
    generateInvoice() {
      const conf = {
        title: 'Warning',
        color: 'error',
        icon: 'alert',
      }
      this.$confirm('Are you sure you want to generate invoice?', conf)
        .then(confirm => {
          if (!confirm) return
          this.attachInvoice()
        })
    },
    storeOrderDetail() {
      const repository = orderDetailRepository.store
      const data = objectChangeKeyCase(this.orderDetailData, 'camelToSnakeCase')
      orderDetailPostHandler.setVue(this)
      orderDetailPostHandler.execute(repository, [data], this.storeOrderDetailStatusOk)
    },
    storeOrderDetailStatusOk() {
      this.resetEditDialog()
    },
    setInvoiceDueDate() {
      const { orderDate = '', paymentTerms } = this.orderData
      this.invoiceData.dueDate = orderDate.addDays(paymentTerms)
    },
    getInvoiceData() {
      const { orderId } = this.$route.params
      const notAllowed = ['id']
      const invoiceData = this.$objectFilterKeys(this.invoiceData, notAllowed)
      const result = { orderId, ...invoiceData }
      return this.$objectChangeKeyCase(result, 'camelToSnakeCase')
    },
    updateInvoiceAmountDue() {
      this.invoiceData.amountDue = this.totalAmount
    },
    invoiceStoreStatusOk() {
      const { orderId } = this.$route.params
      this.$router.push({ name: 'orders.show', params: { orderId } })
    },
    updateOrder() {
      if (this.orderPutting) return
      if (this.orderData.invoiceCount) {
        this.updateOrderWithInvoice()
        return
      }
      this.updateOrderNoInvoice()
    },
    updateOrderWithInvoice() {
      const requestVarNames = this.putVarNames('order')
      const request = new VuePostApi(this, requestVarNames)
      const data = this.prepareOrderDataForUpdate()

      const { orderId } = this.$route.params
      const repository = orderRepository.updateInvoiced(orderId, data)
      request.send(repository, this.updateOrderStatusOk)
    },
    updateOrderNoInvoice() {
      const requestVarNames = this.putVarNames('order')
      const request = new VuePostApi(this, requestVarNames)
      const data = this.prepareOrderDataForUpdate()
      const { orderId } = this.$route.params
      const repository = orderRepository.update(orderId, data)
      request.send(repository)
    },
    updateOrderStatusOk() {
      const { orderId } = this.$route.params
      this.$router.push({ name: 'orders.show', params: { orderId } })
    },
    prepareOrderDataForUpdate() {
      const { orderId } = this.$route.params
      const updates = this.$objectChangeKeyCase(this.orderFields, 'camelToSnakeCase')
      return { ...updates, orderId }
    },
    openEditDetailDialog(item) {
      this.$refs.editDetail.setInputVars(item)
      this.editDetailDialog = true
    },
    getOrder() {
      if (this.orderGetting) return
      const { orderId } = this.$route.params
      const requestApiVarNames = this.getOrderGetRequestVarNames()
      const getRequest = new VueGetApi(this, requestApiVarNames)
      const repository = orderRepository.edit(orderId)
      getRequest.fetch(repository, this.orderGetRequestStatusOk)
    },
    updateOrderDetail() {
      if (this.orderDetailPutting) return
      const requestApiVarNames = this.putVarNames('orderDetail')
      const request = new VuePostApi(this, requestApiVarNames)
      const editOrderDetailData = this.$objectChangeKeyCase(this.editOrderDetailData, 'camelToSnakeCase')
      const { id } = editOrderDetailData
      const data = editOrderDetailData
      const repository = orderDetailRepository.update(id, data)
      request.send(repository, this.orderDetailPutStatusOk)
    },
    orderDetailPutStatusOk() {
      this.resetEditDialog()
    },
    resetEditDialog() {
      const defaults = orderDetailDialogVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    postVarNames(baseName) {
      return {
        loading: `${baseName}Posting`,
        error: `${baseName}PostErrors`,
      }
    },
    putVarNames(baseName) {
      return {
        loading: `${baseName}Putting`,
        error: `${baseName}PutErrors`,
      }
    },
    orderGetRequestStatusOk({ data }) {
      const result = {}
      Object.keys(data).forEach(key => {
        if (key === 'details') {
          this.orderDetails = data[key].map(item => objectChangeKeyCase(item))
          return
        }
        result[key.snakeToCamelCase()] = data[key]
      })
      this.orderData = result
    },
    getOrderGetRequestVarNames() {
      const BASE_NAME = 'order'
      return {
        loading: `${BASE_NAME}Getting`,
        error: `${BASE_NAME}GetErrors`,
      }
    },
    initWebsockets() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
        .listen('DBDeleteEvent', this.dbDeleteEvent)
    },
    dbDeleteEvent({ id, model }) {
      if (!id || !model) return
      if (model === 'OrderDetail') {
        this.orderDetailDBDeleteEvent(id)
      }
    },
    dbStoreEvent({ data, model }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'OrderDetail') {
        this.orderDetailDBStoreEvent(data)
      }
    },
    orderDetailDBDeleteEvent(id) {
      id = Number(id)
      this.orderDetails = this.orderDetails
        .filter(item => item.id !== id)
        .map(item => ({ ...item }))
    },
    orderDetailDBStoreEvent(data) {
      this.orderDetails.push(objectChangeKeyCase(data))
    },
    dbUpdateEvent({ data, model }) {
      if (!model || this.$objectEmpty(data)) return
      this.rerouteDBUpdateEvent({ model, data })
    },
    rerouteDBUpdateEvent({ model, data }) {
      if (model === 'OrderDetail') {
        this.orderDetailDBUpdateEvent(data)
        return
      }
      if (model === 'Order') {
        this.orderDBUpdateEvent(data)
      }
    },
    orderDBUpdateEvent(data) {
      this.updateOrderFields(data)
    },
    updateOrderFields(data) {
      this.orderData = Object.keys(this.orderData)
        .reduce((result, key) => {
          result[key] = data[key.camelToSnakeCase()]
          return result
        }, {})
    },
    orderDetailDBUpdateEvent(data) {
      this.orderDetails = this.orderDetails.map(item => {
        if (data.id === item.id) item = { ...Window.objectChangeKeyCase(data) }
        return { ...item }
      })
    },
  },
}
</script>
